@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';

/*
	Venue by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

/* Button */

	.button {
		position: relative;
		-ms-behavior: url('assets/js/ie/PIE.htc');
	}

/* Form */

	form {
		input[type=text],
		input[type=password],
		select,
		textarea {
			position: relative;
			-ms-behavior: url('assets/js/ie/PIE.htc');
		}
	}

/* Nav */

	#nav {
		> ul {
			> li {
				> span, > a {
					position: relative;
					-ms-behavior: url('assets/js/ie/PIE.htc');
				}
			}
		}
	}

	.dropotron {
		-ms-behavior: url('assets/js/ie/PIE.htc');

		a, span {
			border: 0;
		}

		&.level-0 {
			margin: 0;

			&:before {
				display: none;
			}
		}
	}

/* Banner */

	#banner {
		-ms-behavior: url('assets/js/ie/backgroundsize.min.htc');
	}

/* Newsletter */

	#newsletter {
		.text, .submit {
			-ms-behavior: url('assets/js/ie/PIE.htc');
		}
	}
